@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.11/semantic.min.css");

.sticky-btn {
    position: fixed !important;
    bottom: 50px;
    right: 50px;
    z-index: 9999;
    width: 140px;
    background: #2E7EB8 !important;
    opacity: 0.8;
    font-family: Montserrat;
    color: white !important;

}

.sticky-btn-previous {
    position: fixed !important;
    bottom: 100px;
    right: 50px;
    width: 140px;
    z-index: 9999;
    opacity: 0.8;
    background: #2E7EB8 !important;
    font-family: Montserrat;
    color: white !important;
}

.image-gallery-slide img {
    object-fit: contain !important;
}

.image-gallery-image {
    background-color: #B82E60 !important;
}

.ui.dropdown .menu {
    height: auto !important;
}

h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 63px;
    text-align: center;
    color: #B82E60;
}

h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 52px;
    line-height: 63px;
    text-align: center;
}

h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #2E7EB8;
}

h4 {

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: #2E7EB8;
}

h5 {
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 28px;
    text-align: center;
    color: #B82E60;
}

p {
    white-space: pre-wrap !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;
}

.itinerary_row {
    max-height: 400px !important;
}

.imagesContainer {
    max-width: 703px !important;
    max-height: 530px !important;
    width: 100% !important;
    height: 100% !important;
}

.tripDates {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 63px;
    color: #2E7EB8;
    margin-left: 10px;
}

.specialColumn {
    width: '321px' !important;
    height: '350px' !important;

}

.schoolName {
    color: #B82E60;

}

.row {
    max-width: 1460px !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.logo_text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold !important;
    font-size: 52px;
    line-height: 63px;
    display: flex;
    align-items: right;
    max-width: 244px;
    width: 100%;
}

.logo_erasmus {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold !important;
    font-size: 52px;
    line-height: 63px;
    display: flex;
    align-items: right;
    max-width: 244px;
    width: 100%;
}

.menu {
    background-color: white !important;
    height: 114px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.menu_items {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B82E60 !important;
}

.active {
    text-decoration: underline !important;
    text-underline-offset: 10px !important;
}

.rectangle_3 {
    position: absolute;
    width: 220px;
    height: 220px;
    border: 3px solid #2E7EB8;
    border-radius: 49px;
    right: -100px;
    bottom: -110px;
    z-index: 10;
}

.rectangle_1 {
    position: relative;
    width: 100%;
    height: 463px;
    border: 3px solid #B82E60;
    border-radius: 49px;
}

.rectangle_2 {
    position: absolute;
    z-index: 10;
    width: 220px;
    height: 220px;
    top: -150px;
    left: -100px;
    border: 3px solid #2E7EB8;
    border-radius: 49px;
}

.aboutColumn {
    margin-top: 150px !important;
    float: right !important;
}

body {
    font-family: Montserrat !important
}

.about_paragraph {
    text-align: left;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;
    color: #333333 !important;
}

.icons>img {
    width: 32px;
    height: 32px;
}

.blog_btn {
    background: #2E7EB8 !important;
    max-width: 344px;
    width: 100%;
    font-family: Montserrat;
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 32px;
    height: 65px;
    border-radius: 19px !important;
    color: white !important;
    float: left;
}

.test {
    width: 100px !important;
}

.blog_btn_long {
    background: white !important;
    max-width: 444px;
    width: 100%;
    font-family: Montserrat;
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 32px;
    height: 65px;
    border-radius: 19px !important;
    border: #2E7EB8 2px solid !important;
    color: #2E7EB8 !important;
    float: left;
}

.itinerary_logo {
    display: flex;
    max-width: 704px;
    height: 252px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background: transparent;
    border-radius: 49px;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    color: white !important;
    border: 3px solid #B82E60;
    text-align: center;
    line-height: 63px;
}

.itinerary_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold !important;
    font-size: 28px;
    line-height: 150%;
}

.itinerary_details {
    display: block !important;
}

.itinerary_link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;

    color: #2E7EB8;
}

.itinerary_arrow {
    width: 59px !important;
}

.itinerary_box_text {
    font-family: Montserrat;
    font-style: normal;
    font-size: 22px;
}

.display_rectangle_3 {
    position: absolute;
    width: 235px;
    height: 235px;
    right: -150px;
    top: -150px;
    border: 3px solid #2E7EB8;
    border-radius: 49px;

}

.display_rectangle_1 {
    position: relative;
    max-width: 844.47px !important;
    height: 301px !important;
    display: flex;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background: transparent;
    border-radius: 49px;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    color: white !important;
    border: 3px solid #B82E60;
    text-align: center;
    line-height: 63px;
}

.display_rectangle_2 {
    position: absolute;
    width: 253px;
    height: 253px;
    top: 220px;
    left: -80px;
    border: 3px solid #2E7EB8;
    border-radius: 49px;
}

.route_square {
    position: relative;
    width: 223px;
    height: 223px;
    background-color: #B82E60;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 49px;
    border: 3px solid #B82E60;
}

.max_width_trip_map_container {
    max-width: 949px;
}

.tripMap {
    width: 100%;
    max-height: 414px;
    max-width: 949px;
    min-height: 149px;
}

.vertical {
    border-left: 3px solid #2E7EB8;
    height: 120px;
    position: absolute;
    bottom: -120px;
    right: 111px;
    z-index: -1;
    margin-top: -5px;
}

.field {
    display: block !important;
}

.ui.input {
    width: 100%
}

.scrollableP {
    white-space: pre-wrap !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow-y: scroll;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    /* number of lines to show */
    line-clamp: 10;
    -webkit-box-orient: vertical;
}

.scrollableP::-webkit-scrollbar {
    display: none;
}

.routeNameLeft {
    width: 322px;
    height: 153px;
    position: absolute;
    right: 250px;
    text-align: right;
    display: flex;
    align-items: center;
}

.routeNameRight {
    width: 322px;
    height: 153px;
    position: absolute;
    left: 250px;
    text-align: left;

}

.from {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 52px;
    line-height: 63px;

}

.city {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 63px;
    word-wrap: break-word;
    max-width: 322px;
}

.supervisingTeachersList {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: #B82E60;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer;
}

.supervisingTeachersImgBox {
    position: relative;
    width: 209px;
    height: 209px;
    right: -150px;
    top: -150px;
    border: 3px solid #2E7EB8;
    box-sizing: border-box;
    border-radius: 49px;
    z-index: 10;
}

.supervisingTeachersImgBox1 {
    position: relative;
    width: 226px;
    height: 226px;
    left: 0px;
    border: 3px solid #B82E60;
    box-sizing: border-box;
    border-radius: 49px;
}

.supervisingTeachersImgContainer {
    margin-right: 50px;
    display: inline-block;
    width: 300px;
    height: 300px;
}

.image_container {
    position: relative;
    width: 100% !important;
    height: 368px !important;
    border: 3px solid #2E7EB8;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 49px;
    margin-right: 15px;
    float: left;
}

.relative_container {
    position: relative;
}

.friendFullName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #2E7EB8;
}

.talesImgs {
    width: 223px;
    height: 223px;
    border: 3px solid #2E7EB8;
    box-sizing: border-box;
    border-radius: 49px;
    float: right;
}

.friendshipNav:hover {
    cursor: pointer;
}

.image-gallery-image {
    border: 3px solid #B82E60;
    box-sizing: border-box;
    border-radius: 49px;
    min-height: 256px !important;
    object-fit: fill !important;
}

.image-gallery-right-nav:hover {
    color: #2E7EB8 !important;
}

.E {
    color: #2E7EB8;
}

.download_text {
    color: #2E7EB8;
    font-size: 16px;
}

.fullColumnPhoto {
    width: 100%;
    height: 414px;
    border: 3px solid #2E7EB8;
    box-sizing: border-box;
    border-radius: 49px;
}

.marginSegment {
    margin-top: 50px !important;
}

.marginTopSpecial {
    margin-top: 150px;
}

.videoColumn {
    width: 100% !important;
    max-width: 900px !important;
    text-align: left;
}

.sidebarIcon {
    padding: 50px;
    font-size: 52px;

}

.ui.wide.right.sidebar {
    max-width: 300px !important;
}

.item {
    margin-top: 10px !important;
}

.hamburgerIcon {
    color: #2E7EB8;
}

.aboutGrid {
    margin-top: 250px !important;
    padding: 25px !important;
    min-height: 100vh;
}

.bordered_pink {
    border: 3px solid #B82E60;
    box-sizing: border-box;
    border-radius: 49px;
}

.padded_grid {
    padding: 25px !important;
}

.marginTop_MainGrid {
    margin-top: 250px !important;
}

.left_arrow_img {
    position: absolute;
    top: 130px;
    left: -50px;
    z-index: 1000;
}

.right_arrow_img {
    position: absolute;
    top: 130px;
    right: -50px;
}

.localization>iframe {
    max-width: 344px;
    height: 344px;
    border: 3px solid #2E7EB8 !important;
    border-radius: 49px;
}

.rotation>iframe {
    width: 100%;
    max-width: 900px;
    height: 384px;
    border: 3px solid #2E7EB8;
    box-sizing: border-box;
    border-radius: 49px;
}

.number {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 95px;
    line-height: 116px;
    color: #2E7EB8;
}

.image-gallery-right-nav:hover {
    color: #2E7EB8;
}

.two_links {
    height: 100pt;
    visibility: hidden;
}

.one_links {
    height: 80pt;
    visibility: hidden;

}

@media (max-width: 992px) {
    .marginTop_MainGrid {
        margin-top: 100px !important;
    }

    .aboutColumn {
        margin-left: 0px !important;
    }

    .talesImgs {
        float: none;
        margin-bottom: 10px !important;
    }

    .videoColumn {
        text-align: center !important;
    }

    .marginTopSpecial {
        margin-top: 350px;
    }

    .supervisingTeachersList {
        text-align: center;
    }

    .routeNameLeft {
        margin-right: 50px;
    }

    .route_square {
        width: 140px;
        height: 140px;
    }

    .routeNameRight {
        width: 200px;
        height: 153px;
        display: block;
        align-items: center;
        left: 150px;
    }

    .from {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 32px;
    }

    .city {
        width: 180px !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        word-wrap: break-word
    }

    .vertical {
        border-left: 3px solid #2E7EB8;
        height: 120px;
        position: absolute;
        bottom: -120px;
        right: 70px;
        z-index: -1;
        margin-top: -5px;
    }

    .schoolName {
        margin-left: 25px;
        margin-right: 25px;
    }
}

@media (max-width:584px) {
    .two_links {
        height: 80pt;
    }

    .one_links {
        height: 50pt;
    }

    .planeIcon {
        height: 26px;
        width: 26px;
    }

    .row {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .gallery_nav {
        width: 46px;
        height: 46px;
    }

    .rotation>iframe {
        width: 100%;
        height: 198px;
        border-radius: 34px;
    }

    .tripMap {
        height: 149px !important;
    }

    .display_rectangle_1 {
        height: 121px !important;
        border-radius: 25px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .heroP {
        text-align: center;
        margin-top: 25px !important;
    }

    .fullColumnPhoto {
        width: 100%;
        height: 150px;
        border-radius: 30px;
    }

    h4 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
    }

    .locationIcon {
        width: 14px;
        height: 18px;
    }

    .talesImgs {
        width: 100%;
        height: 200px;
        border: 3px solid #2E7EB8;
        box-sizing: border-box;
        border-radius: 25px;
    }

    .icons a>img {
        width: 19px !important;
        height: 19px !important;
    }

    h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
    }

    .vertical {
        border-left: 3px solid #2E7EB8;
        height: 120px;
        position: absolute;
        bottom: -120px;
        right: 70px;
        z-index: -1;
        margin-top: -5px;
    }

    .routeNameRight {
        width: 200px;
        height: 153px;
        display: block;
        align-items: center;
        left: 150px;
    }

    .from {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 32px;
    }

    .city {
        width: 180px !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        word-wrap: break-word
    }

    h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
    }

    h2 {

        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 32px;
        text-align: center;

    }

    .marginTopSpecial {
        margin-top: 250px !important;
    }

    p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
    }

    .about_paragraph {
        text-align: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #333333 !important;
    }

}

@media (max-width:574px) {
    .sticky-btn {
        position: fixed !important;
        bottom: 50px;
        right: 10px;
        z-index: 9999;

    }

    .sticky-btn-previous {
        position: fixed !important;
        bottom: 100px;
        right: 10px;
        width: 140px;
        z-index: 9999;
        opacity: 0.8;
    }

    .download_text {
        font-size: 10px;
    }

    .logo_img {
        width: 100px !important;
    }

    .menu {
        height: 90px !important;
    }

    .itinerary_row {
        max-height: 100% !important;
    }

    .tripDates {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        color: #2E7EB8;
    }

    .itinerary_box_text {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    h5 {
        font-family: Montserrat;
        font-style: italic;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #B82E60;
    }

    .blog_btn {
        background: #2E7EB8 !important;
        max-width: 344px;
        width: 100%;
        font-family: Montserrat;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 32px;
        height: 40px;
        border-radius: 19px !important;
        color: white !important;
        float: left;
    }

    .blog_btn_long {
        background: white !important;
        max-width: 444px;
        width: 100%;
        font-family: Montserrat;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 32px;
        height: 40px;
        border-radius: 19px !important;
        border: #2E7EB8 2px solid !important;
        color: #2E7EB8 !important;
        float: left;
    }

    .left_arrow_img {
        position: absolute;
        top: 130px;
        left: -30px;
        width: 50px;
        height: 50px;
        z-index: 10000;
    }

    .right_arrow_img {
        position: absolute;
        top: 130px;
        right: -30px;
        width: 50px;
        height: 50px;
    }

    .relative_container {
        width: 100% !important;
        height: 100% !important;
    }

    .image_container {
        width: 100%;
        height: 344px !important;
        border-radius: 40px;
    }

    .route_square {
        width: 140px;
        height: 140px;
    }

    .flight_dates {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;

    }

    .flight_dates>img {
        width: 35.95px;
        height: 37.84px;
    }

    .rectangle_1 {
        position: relative;
        width: 100%;
        height: 263px;
        border: 3px solid #B82E60;
        border-radius: 49px;
    }

    .supervisingTeachersImgBox1 {
        height: 180px;
        width: 180px;
        left: -65px;


    }

    .supervisingTeachersImgBox {
        height: 170px;
        width: 170px;
        left: 50px;
    }

    .supervisingTeachersImgContainer {
        height: 200px;
        margin-right: 100px;
    }
}

@media (max-width:766px) {
    .aboutGrid {
        margin-top: 100px !important;
    }

    .itinerary_logo {
        font-size: 40px;
    }
}